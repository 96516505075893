import { Editor, Element as SlateElement } from "slate";
import {
  CustomEditor,
  MessageBoxType,
  MessageBoxElement,
} from "components/ManualBuilder/types";

export const getParentMessageBox = (
  editor: CustomEditor,
  path: number[]
): MessageBoxType => {
  try {
    const [node] = Editor.parent(editor, path);
    if (SlateElement.isElement(node) && node.type === "message-box") {
      return (node as MessageBoxElement).boxType;
    }
    return "warning"; // fallback
  } catch {
    return "warning"; // fallback
  }
};

export const getPictogramUrl = (boxType: MessageBoxType): string => {
  const picto = {
    info: `${process.env.REACT_APP_DATA_URL}/pictograms/svgs/C004Info`,
    warning: `${process.env.REACT_APP_DATA_URL}/pictograms/svgs/C001Warn`,
    danger: `${process.env.REACT_APP_DATA_URL}/pictograms/svgs/C003Danger`,
    attention: `${process.env.REACT_APP_DATA_URL}/pictograms/svgs/C002Attention`,
  };

  return picto[boxType];
};

export const getMessageBoxStyle = (
  boxType: MessageBoxType
): Record<string, string> => {
  const styles = {
    info: {
      box: "border border-blue-400 rounded-md my-4 overflow-hidden flex",
      pictogram: "w-16 h-16 pl-4 flex-shrink-0 self-center",
      wrapper: "flex-grow",
      header: "bg-blue-600 px-4 py-2 font-semibold text-white rounded-bl-lg",
      content: "p-4",
    },
    attention: {
      box: "border border-yellow-400 rounded-md my-4 overflow-hidden flex",
      pictogram: "w-16 h-16 pl-4 flex-shrink-0 self-center",
      wrapper: "flex-grow",
      header: "bg-yellow-400 px-4 py-2 font-semibold text-white rounded-bl-lg",
      content: "p-4",
    },
    warning: {
      box: "border border-orange-400 rounded-md my-4 overflow-hidden flex",
      pictogram: "w-16 h-16 pl-4 flex-shrink-0 self-center",
      wrapper: "flex-grow",
      header: "bg-orange-500 px-4 py-2 font-semibold text-white rounded-bl-lg",
      content: "p-4",
    },
    danger: {
      box: "border border-red-400 rounded-md my-4 overflow-hidden flex",
      pictogram: "w-16 h-16 pl-4 flex-shrink-0 self-center",
      wrapper: "flex-grow",
      header: "bg-red-600 px-4 py-2 font-semibold text-white rounded-bl-lg",
      content: "p-4",
    },
  };

  return styles[boxType];
};
