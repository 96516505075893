import React from "react";
import { Project } from "types";
import { AxiosInstance } from "axios";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { useClient } from "utils/client";
import { FeedbackContext } from "context/Feedback";
import { FeedbackLocation } from "components/Feedback/FeedbackPopup";
import { DocType } from "components/Documents/types";
import { ControlContext } from "context/Controls";
import { useTranslation } from "react-i18next";

interface GeneratePDFParams {
  selectedDocs: DocType[];
  selectedLanguages: string[];
}

const generatePDF = async (
  client: AxiosInstance,
  projectId: string,
  { selectedDocs, selectedLanguages }: GeneratePDFParams
) =>
  client.post(`/projects/${projectId}/pdf`, {
    filters: selectedDocs,
    languages: selectedLanguages,
  });

export const useProjectDetails = (projectId: string) => {
  const client = useClient();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { displayFeedback } = React.useContext(FeedbackContext);
  const { displayNotification } = React.useContext(ControlContext);

  const { data: project, isLoading: loadingQuery } = useQuery<Project>(
    ["projects", projectId],
    async () => {
      const { data } = await client.get(`/projects/${projectId}`);

      return data;
    },
    {
      onError: (e) => {
        displayNotification!(
          "Kann Projektdaten nicht herunterladen. Die Seite bitte manuell neuladen.",
          // @ts-ignore
          (e as Error).response.data || (e as Error).message,
          "error",
          false
        );
      },
    }
  );

  const { mutate: updateVersion, isLoading: loadingMutation } = useMutation(
    async (params: GeneratePDFParams) =>
      await generatePDF(client, projectId, params),
    {
      onError: (e) => {
        displayNotification!(
          t("documents.download.generate_failure"),
          // @ts-ignore
          (e as Error).response.data || (e as Error).message,
          "error"
        );
      },
      onSuccess: () => {
        queryClient.refetchQueries(["projects", projectId]);

        displayFeedback!(FeedbackLocation.Documents);
        displayNotification!(t("documents.download.generate_success"), "");
      },
    }
  );

  return {
    project,
    updateVersion,
    fetchInProgress: loadingQuery,
    updateInProgress: loadingMutation,
  };
};
